import { SVGProps } from "react"

const BurgerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.667 6L4.66699 6"
      stroke={props.color || "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.667 12L4.66699 12"
      stroke={props.color || "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.667 18H4.66699"
      stroke={props.color || "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)
export default BurgerIcon
