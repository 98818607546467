"use client"

import { useTheme } from "@emotion/react"

const AccordionExpandIconSmall = () => {
  const theme = useTheme()
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6247 15.207L15.5863 18.1687C16.0908 18.6731 16.9086 18.6731 17.413 18.1687L20.3747 15.207M3.58301 22.957L3.58301 10.0404C3.58301 6.47353 6.4745 3.58203 10.0413 3.58203L22.958 3.58203C26.5248 3.58203 29.4163 6.47353 29.4163 10.0404V22.957C29.4163 26.5239 26.5248 29.4154 22.958 29.4154H10.0413C6.4745 29.4154 3.58301 26.5239 3.58301 22.957Z"
        stroke={theme.colors.faqIconColorAlternative}
        stroke-width="1.9375"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default AccordionExpandIconSmall
