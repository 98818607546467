"use client"
import { useTheme } from "@emotion/react"

const AccordionCloseIconSmall = () => {
  const theme = useTheme()
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6247 16.793L15.5863 13.8313C16.0908 13.3269 16.9086 13.3269 17.413 13.8313L20.3747 16.793M3.58301 9.04297L3.58301 21.9596C3.58301 25.5265 6.4745 28.418 10.0413 28.418L22.958 28.418C26.5248 28.418 29.4163 25.5265 29.4163 21.9596V9.04297C29.4163 5.47613 26.5248 2.58464 22.958 2.58464H10.0413C6.4745 2.58464 3.58301 5.47613 3.58301 9.04297Z"
        stroke={theme.colors.faqIconColor}
        stroke-width="1.9375"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default AccordionCloseIconSmall
