import { SVGProps } from "react"

const SimplyIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_25222_1022"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={props.width || "75"}
        height={props.height || "16"}
      >
        <path
          d="M13.3266 0.521805V2.57971H15.8282V13.1676H13.3266V15.2255H20.579V13.1676H18.0544V2.57971H20.579V0.521805H13.3266ZM34.7625 0.521805L30.8532 8.59276H30.792L26.8445 0.521805H24.32V15.2331H26.5079V9.70968C26.5079 6.70316 26.462 4.18625 26.462 4.1633H26.5232C26.5232 4.1633 27.0511 5.40263 28.0609 7.50643L30.0576 11.6452H31.4423L33.4619 7.46053C34.4488 5.40263 34.9537 4.1786 34.9767 4.1633H35.0226C35.0226 4.1633 34.9767 6.70316 34.9767 9.70968V15.2331H37.2029V0.521805H34.7625ZM43.3383 2.57971H45.4421C47.3317 2.57971 48.3874 3.45948 48.3874 5.27257C48.3874 7.08567 47.4006 7.96544 45.4421 7.96544H43.3383V2.58736V2.57971ZM41.1045 0.521805V15.2331H43.3307V9.93919H45.4345C48.6705 9.93919 50.606 8.2179 50.606 5.24962C50.606 2.28135 48.6705 0.521805 45.4345 0.521805H41.1045ZM53.6508 0.521805V15.2331H62.4102V13.1523H55.877V0.521805H53.6508ZM72.5314 0.521805L69.9227 5.08132C69.3337 6.09114 68.9129 6.93266 68.89 6.94797H68.8517C68.8517 6.94797 68.3927 6.08349 67.8036 5.05837L65.2408 0.521805H62.701L67.7042 9.15887V15.2331H69.9074V9.15887L74.9107 0.521805H72.5391H72.5314ZM0.359559 4.2398C0.359559 7.18512 2.63167 8.01899 4.49831 8.63101C6.18135 9.19712 8.048 9.41133 8.048 11.3392C8.048 12.7698 7.03817 13.4812 5.37808 13.4812C3.52674 13.4812 2.31036 12.6015 2.1191 10.9414L0 11.5075C0.481962 14.5982 2.94532 15.5009 5.40103 15.5009C8.51466 15.5009 10.2972 14.0703 10.2972 11.3162C10.2972 8.2485 8.048 7.42993 6.02835 6.81791C4.07755 6.22885 2.62401 5.83104 2.62401 4.1939C2.62401 2.95457 3.44258 2.2431 5.07973 2.2431C6.80102 2.2431 7.72669 3.02342 8.0633 4.49225L10.1824 3.92614C9.61629 1.31743 7.65784 0.246399 5.07208 0.246399C2.11145 0.246399 0.344259 1.69994 0.344259 4.2398"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_25222_1022)">
        <path d="M86.9292 -1.18414H52.4651V16.9239H86.9292V-1.18414Z" fill="#FAB03B" />
        <path
          d="M60.23 -1.4903H68.6375L68.9053 6.94021L66.1283 12.0505L60.23 -1.4903Z"
          fill="#ED1C24"
        />
        <path
          d="M55.8848 13.1368L63.7186 10.6122L63.8945 16.3422H55.9078L55.8848 13.1368Z"
          fill="#ED1C24"
        />
        <path d="M34.9844 -1.68896H18.0622V17.4442H34.9844V-1.68896Z" fill="#0070BD" />
        <path
          d="M33.026 16.5562L34.9768 4.14763V-0.427185L51.8531 0.521438V15.2251L33.026 16.5562Z"
          fill="#38B54A"
        />
        <path d="M40.3856 15.891L40.3091 9.93146H43.3538L44.6391 15.891H40.3856Z" fill="#FAB03B" />
        <path d="M18.0546 -0.0978394H-0.259949V15.8222H18.0546V-0.0978394Z" fill="#ED1F78" />
      </g>
      <path
        d="M15.4542 27.578V28.1275H14.3882V22.5009H15.4542V24.424C15.7399 24.0724 16.1465 23.8526 16.663 23.8526C17.751 23.8526 18.4763 24.6878 18.4763 26.0835C18.4763 27.4791 17.751 28.1935 16.729 28.1935C16.1795 28.1935 15.7399 27.9627 15.4542 27.578ZM16.4872 27.2813C17.1795 27.2813 17.4763 26.7209 17.4763 26.0065C17.4763 25.2922 17.1685 24.7537 16.4872 24.7537C15.8058 24.7537 15.4652 25.3032 15.4542 26.0175C15.4542 26.7318 15.8058 27.2703 16.4872 27.2703V27.2813Z"
        fill="#737477"
      />
      <path
        d="M20.4984 28.105L18.9049 24.1048V23.929H20.0369L21.1029 27.1599L22.1359 23.929H23.2788V24.1048L21.6963 28.072C21.2677 29.0941 20.7402 29.7644 19.3445 29.7534V28.7864C19.894 28.7864 20.2237 28.6215 20.4874 28.094L20.4984 28.105Z"
        fill="#737477"
      />
      <path
        d="M26.213 22.8741H28.4769C29.4549 22.8741 30.1143 23.3247 30.1143 24.2259C30.1143 24.8633 29.7736 25.2039 29.422 25.3578C29.7956 25.4677 30.3451 25.8523 30.3451 26.6436C30.3451 27.4348 29.9385 28.1272 28.5648 28.1272H26.202V22.8851L26.213 22.8741ZM28.367 24.9951C28.8835 24.9951 29.0813 24.7534 29.0813 24.4127C29.0813 24.072 28.8505 23.8522 28.323 23.8522H27.29V24.9951H28.367ZM28.4769 27.1491C29.0703 27.1491 29.3011 26.8524 29.3011 26.4568C29.3011 26.0611 29.0154 25.7864 28.4988 25.7864H27.29V27.1381H28.4769V27.1491Z"
        fill="#737477"
      />
      <path
        d="M31.1144 25.5228C31.1144 23.8524 32.1913 22.8083 33.642 22.8083C34.7079 22.8083 35.4552 23.3688 35.7959 24.0282L34.8288 24.4788C34.5981 24.0721 34.2244 23.8304 33.631 23.8304C32.7738 23.8304 32.1913 24.4788 32.1913 25.5118C32.1913 26.4569 32.7408 27.1822 33.6639 27.1822C34.1695 27.1822 34.5871 26.9624 34.8398 26.6767V26.0393H33.598V25.1381H35.8179V27.1492C35.3893 27.7537 34.598 28.2042 33.6859 28.2042C32.1034 28.2042 31.1034 27.1273 31.1034 25.5228H31.1144Z"
        fill="#737477"
      />
      <path
        d="M39.0706 22.8741H41.3345C42.3016 22.8741 42.972 23.3247 42.972 24.2259C42.972 24.8633 42.6313 25.2039 42.2796 25.3578C42.6533 25.4677 43.2027 25.8523 43.2027 26.6436C43.2027 27.4348 42.7961 28.1272 41.4224 28.1272H39.0597V22.8851L39.0706 22.8741ZM41.2136 24.9951C41.7301 24.9951 41.9279 24.7534 41.9279 24.4127C41.9279 24.072 41.6972 23.8522 41.1697 23.8522H40.1366V24.9951H41.2136ZM41.3235 27.1491C41.917 27.1491 42.1477 26.8524 42.1477 26.4568C42.1477 26.0611 41.862 25.7864 41.3455 25.7864H40.1366V27.1381H41.3235V27.1491Z"
        fill="#737477"
      />
      <path
        d="M43.9612 25.5338C43.9612 23.8194 44.9832 22.8083 46.5218 22.8083C48.0603 22.8083 49.0494 23.8304 49.0494 25.5338C49.0494 27.2372 48.0603 28.1932 46.5218 28.1932C44.9832 28.1932 43.9612 27.2042 43.9612 25.5338ZM46.5218 27.1822C47.4449 27.1822 47.9724 26.5228 47.9724 25.5338C47.9724 24.5447 47.4449 23.8304 46.5218 23.8304C45.5986 23.8304 45.0382 24.5007 45.0382 25.5338C45.0382 26.5668 45.5986 27.1822 46.5218 27.1822Z"
        fill="#737477"
      />
      <path
        d="M50.0823 22.8741H50.9614L53.6099 26.3798V22.8741H54.6759V28.1162H53.8077L51.1373 24.6764V28.1162H50.0713V22.8741H50.0823Z"
        fill="#737477"
      />
      <path
        d="M55.9728 22.8741H58.0498C59.5444 22.8741 60.5225 23.8962 60.5225 25.5336C60.5225 27.1711 59.5444 28.1162 58.0498 28.1162H55.9728V22.8741ZM58.0608 27.0941C58.9839 27.0941 59.4565 26.4348 59.4565 25.5226C59.4565 24.5556 58.9729 23.8962 58.0608 23.8962H57.0498V27.0941H58.0608Z"
        fill="#737477"
      />
    </svg>
  )
}

export default SimplyIcon
