"use client"

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { useState } from "react"
import { extractProperty, footerMenuClick, getNonUmbracoUrl } from "utils"
import ImageWithAlt from "../../ImageWithAlt"
import { Mobile } from "../../common/bit/MediaQueries"
import { FooterArrowIcon, SocialIcon } from "../../icons"

const StyledSocials = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`
const Social = styled.div<{ hover: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.footerSocialsBackgroundColor};
  border-radius: 100%;
`

const StyledLogoLink = styled(Link)<{ $locale: string }>`
  height: 56px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  gap: 0px !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.footerLogoLinkBackgroundColor};

  svg {
    transform: ${(p) => (p.$locale === "he" ? "none" : "rotate(180deg)")};
  }
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
type Props = {
  mobileSocialUrls: any
  parentSiteLogoMobile: any
  parentLinkMobile: any
  companyName?: string
}

function Icon({
  type,
  companyName,
}: {
  type: "instagram" | "linkedin" | "facebook"
  companyName?: string
}) {
  const [hover, setHover] = useState(false)
  const theme = useTheme()
  return (
    <Social hover={hover} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <SocialIcon
        fill={theme.colors.socialColor}
        type={type}
        hover={hover}
        companyName={companyName}
      />
    </Social>
  )
}
function SocialsMobile({
  mobileSocialUrls,
  parentSiteLogoMobile,
  parentLinkMobile,
  companyName,
}: Props) {
  const locale = useLocale()
  const theme = useTheme()
  return (
    <Mobile>
      <StyledDiv>
        {parentSiteLogoMobile?.url && (
          <StyledLogoLink
            href={getNonUmbracoUrl(parentLinkMobile[0]?.url || "")}
            $locale={locale}
            onClick={() => footerMenuClick(parentLinkMobile?.altText || "parent site logo", "")}
          >
            <ImageWithAlt
              image={parentSiteLogoMobile}
              width={74}
              height={40}
              style={{ objectFit: "contain" }}
            />
            <FooterArrowIcon fill={theme.colors.corpBlue} />
          </StyledLogoLink>
        )}
        <StyledSocials>
          {mobileSocialUrls.map((item: any, index: number) => {
            const link = extractProperty(item, "link")
            const type = extractProperty(item, "selectSocialIcon") as
              | "instagram"
              | "linkedin"
              | "facebook"

            if (!link?.[0]?.url) return null
            return (
              <Link
                key={index.toString()}
                style={{ width: 40, height: 40 }}
                href={link?.[0]?.url || "#"}
                onClick={() => footerMenuClick(type, "")}
                target="_blank"
                aria-label={type}
              >
                <Icon type={type} companyName={companyName} />
              </Link>
            )
          })}
        </StyledSocials>
      </StyledDiv>
    </Mobile>
  )
}

export default SocialsMobile
