"use client"
import { useTheme } from "@emotion/react"

const AccordionCloseIcon = () => {
  const theme = useTheme()
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="38" height="38" rx="7" fill="white" />
      <rect
        x="1"
        y="1"
        width="38"
        height="38"
        rx="7"
        stroke={theme.colors.faqIconColor}
        strokeWidth="2"
      />
      <path
        d="M19.5174 16.2584L13.9909 22.0536C13.7244 22.333 13.7244 22.7861 13.9909 23.0656L14.6355 23.7416C14.9016 24.0206 15.3328 24.0211 15.5995 23.7427L20 19.15L24.4005 23.7427C24.6672 24.0211 25.0984 24.0206 25.3645 23.7416L26.0091 23.0656C26.2756 22.7862 26.2756 22.3331 26.0091 22.0536L20.4826 16.2584C20.216 15.979 19.7839 15.979 19.5174 16.2584Z"
        fill={theme.colors.faqIconColor}
      />
    </svg>
  )
}

export default AccordionCloseIcon
