import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Button } from "@mui/material"
import { FetchMenusQuery } from "apollo"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import React, { useEffect, useState } from "react"
import {
  Culture,
  extractMenuProperties,
  getNonUmbracoUrl,
  getUmbracoMediaUrl,
  mainMenuClick,
} from "utils"
import Container from "../Container"
import NavLink from "../NavLink/NavLink"
import NewText from "../NewText/NewText"
import { Desktop, Mobile, useIsDesktop } from "../common/bit/MediaQueries"
import { BurgerIcon, CloseCircleIcon } from "../icons"
import SearchIconNew from "../icons/SearchIconNew"
import { SearchModal } from "./SearchModal"
import SideMenu, { StyledButton, StyledLink, StyledList } from "./SideMenu"

const StyledMenuSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const StyledLinkHeb = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  font-size: 16px;
  padding-right: 8px;
  border-right: ${(props) => `1px solid ${props.theme.colors.navTextColor}`};
  line-height: 22px;

  color: ${(props) => props.theme.colors.navTextColor};
`

export const StyledLinkEn = styled(Link)<{ selected: boolean }>`
  position: relative;
  top: 1px;
  text-decoration: none;
  padding-left: 8px;
  border-left: ${(props) => `1px solid ${props.theme.colors.navTextColor}`};
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "900" : "400")};
  font-size: 16px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.navTextColor};
`
const StyledRelative = styled.header`
  position: relative;
  z-index: 9999;
`
const StyledHeader = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 100001;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.navBackgroundColor};
  color: ${(props) => props.theme.colors.navTextColor};
  display: flex;
  height: 64px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 98px;
    padding: 0;
  }
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  align-items: center;
  padding: 0;
`

const ListWrapper = styled.div`
  display: none;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-self: stretch;
    display: flex;
    align-items: stretch;
  }
`

const StyledLanguageSwitcher = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 20px;
  a {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }
`

const StyledEmpty = styled.div`
  width: 24px;
  height: 24px;
`

const StyledSearchButton = styled(Button)`
  min-width: 20px;
  width: 20px;
  height: 20px;
  padding: 0px;
  border-radius: 0px;
`

function Header({
  locale,
  data,
  logo,
  path,
  id,
  searchRoute,
  companyName,
}: {
  logo: any
  locale: string
  data: FetchMenusQuery
  path: any
  id: string[]
  searchRoute: any
  companyName: string
}) {
  const theme = useTheme()
  const pathString = path as string
  const idNumber = id
  const searchRouteString = searchRoute as string
  const culture: Culture = locale === "en" ? "en-us" : "he-il"
  const [open, setOpen] = React.useState<boolean>(false)
  const [openedDesktopMenuIndex, setOpenedDesktopMenuIndex] = React.useState<number>(-1)
  const [modalOpen, setModalOpen] = useState(false)
  const pathname = usePathname()
  const isDesktop = useIsDesktop()
  const [, setSubMenuLink] = useState("")
  // -webkit-overflow-scrolling: touch;

  useEffect(() => {
    if (open || modalOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.touchAction = "none"
      document.body.style.touchAction = "none"
    } else {
      document.documentElement.style.touchAction = "auto"
      document.body.style.overflow = "auto"
      document.body.style.touchAction = "auto"
    }

    // Cleanup to prevent possible side effects when the component unmounts
    return () => {
      document.body.style.overflow = "auto"
      document.documentElement.style.touchAction = "auto"
    }
  }, [open, modalOpen])

  const menuDesktop = data?.menu_desktop?.children

  const handleOpenModal = () => {
    setModalOpen(true)
    mainMenuClick("Search", "")
  }
  const handleCloseModal = () => setModalOpen(false)

  return (
    <StyledRelative role="banner">
      <StyledHeader isOpen={open}>
        <Container>
          <StyledContainer>
            <Mobile>
              <StyledButton
                aria-expanded={open ? true : false}
                aria-label={open ? "סגירת תפריט" : "פתיחת תפריט"}
                onClick={() => {
                  setOpen((prev) => !prev)
                  mainMenuClick(`${open ? "hamburger close" : "hamburger open"}`, "")
                }}
              >
                {open ? (
                  <CloseCircleIcon width={24} height={24} color={theme.colors.navTextColor} />
                ) : (
                  <BurgerIcon width={24} height={24} color={theme.colors.navTextColor} />
                )}
              </StyledButton>
            </Mobile>
            <Desktop>
              <StyledLink
                href={`/${locale === "en" ? "en" : ""}`}
                onClick={() => {
                  mainMenuClick("Logo", "")
                  setSubMenuLink("")
                }}
              >
                <Image
                  style={{ objectFit: "contain", objectPosition: "right" }}
                  src={getUmbracoMediaUrl(logo?.url)}
                  alt={logo?.altText || ""}
                  width={170}
                  height={64}
                  priority
                />
              </StyledLink>
            </Desktop>
            <Mobile>
              <StyledLink
                href={`/${locale === "en" ? "en" : ""}`}
                onClick={() => {
                  mainMenuClick("Logo", "")
                  setSubMenuLink("")
                }}
              >
                <Image
                  style={{ objectFit: "contain", objectPosition: "center" }}
                  src={getUmbracoMediaUrl(logo?.url)}
                  alt={logo?.altText || ""}
                  width={106}
                  height={40}
                  priority
                />
              </StyledLink>
            </Mobile>
            <ListWrapper>
              <StyledList role="navigation">
                {/* Need to figure out how to have types in graphql */}
                {menuDesktop?.map((item: any, index: number) => {
                  const decodedPathname = decodeURI(pathname) + "/"

                  const subActive = item?.children?.some(
                    (sub: any) =>
                      getNonUmbracoUrl(extractMenuProperties(sub).url) === decodedPathname,
                  )
                  const subSubActive = item?.children?.some((sub: any) =>
                    sub?.children?.some(
                      (subSub: any) =>
                        getNonUmbracoUrl(extractMenuProperties(subSub).url) === decodedPathname,
                    ),
                  )
                  const { title, url, name } = item
                    ? extractMenuProperties(item)
                    : { title: "", url: "/", name: "" }
                  return (
                    <NavLink
                      key={index.toString()}
                      href={getNonUmbracoUrl(url)}
                      selected={
                        decodedPathname === getNonUmbracoUrl(url) ||
                        // submenuLink === title ||
                        (name?.length > 0 && decodeURI(pathname).includes(name?.toLowerCase())) ||
                        subActive ||
                        subSubActive
                      }
                      title={title}
                      subCategories={item?.children}
                      isOpen={isDesktop && openedDesktopMenuIndex === index}
                      setOpen={() => {
                        if (openedDesktopMenuIndex === index) {
                          setOpenedDesktopMenuIndex(-1)
                        } else {
                          setOpenedDesktopMenuIndex(index)
                        }
                      }}
                      onClick={() => {
                        setSubMenuLink(title)
                        setOpenedDesktopMenuIndex(-1)
                        mainMenuClick(title, "")
                      }}
                      setSubMenuLink={setSubMenuLink}
                      companyName={companyName}
                      color={theme.colors.navTextColor}
                    />
                  )
                })}
              </StyledList>
            </ListWrapper>
            <Mobile>
              <StyledEmpty>
                <StyledMenuSwitcher>
                  {/* <IconButton
                    LinkComponent={Link}
                    disableRipple
                    href={locale === "he" ? "/contact" : "/en/contact"}
                    style={{
                      padding: 0,
                      borderLeft: locale === "he" ? "1px solid black" : "",
                      borderRight: locale === "en" ? "1px solid black" : "",
                      paddingLeft: locale === "he" ? "8px" : "",
                      paddingRight: locale === "en" ? "8px" : "",
                      borderRadius: 0,
                    }}
                  >
                    <ContactUsIcon />
                  </IconButton> */}
                  {locale === "he" && (
                    <StyledSearchButton onClick={handleOpenModal} aria-expanded={modalOpen}>
                      <SearchIconNew
                        color={theme.colors.navTextColor}
                        width={20}
                        height={20}
                        aria-label="רכיב חיפוש"
                      />
                    </StyledSearchButton>
                  )}
                  {locale === "he" ? (
                    <StyledLinkEn
                      selected={false}
                      href={"/en" + pathname}
                      onClick={() => mainMenuClick("EN", "")}
                    >
                      EN
                    </StyledLinkEn>
                  ) : (
                    <StyledLinkHeb
                      selected={false}
                      href={pathname.replace("/en", "/he")}
                      onClick={() => mainMenuClick(" עב", "")}
                    >
                      עב
                    </StyledLinkHeb>
                  )}
                  {locale === "en" && (
                    <StyledSearchButton onClick={handleOpenModal} aria-expanded={modalOpen}>
                      <SearchIconNew
                        color={theme.colors.navTextColor}
                        width={20}
                        height={20}
                        aria-label="search icon"
                      />
                    </StyledSearchButton>
                  )}
                </StyledMenuSwitcher>
              </StyledEmpty>
            </Mobile>

            <Desktop>
              <StyledLanguageSwitcher>
                {/* <IconButton
                  LinkComponent={Link}
                  disableRipple
                  href={locale === "he" ? "/contact" : "/en/contact"}
                  style={{
                    padding: 0,
                    borderLeft: locale === "he" ? "1px solid black" : "",
                    borderRight: locale === "en" ? "1px solid black" : "",
                    paddingLeft: locale === "he" ? "8px" : "",
                    paddingRight: locale === "en" ? "8px" : "",
                    borderRadius: 0,
                  }}
                >
                  <ContactUsIcon />
                </IconButton> */}
                {locale === "he" && (
                  <StyledSearchButton onClick={handleOpenModal} aria-expanded={modalOpen}>
                    <SearchIconNew
                      color={theme.colors.navTextColor}
                      width={20}
                      height={20}
                      aria-label="רכיב חיפוש"
                    />
                  </StyledSearchButton>
                )}
                <Link
                  href={locale === "he" ? "/en" + pathname : pathname.replace("/en", "/he")}
                  style={{
                    borderRight: `1px solid ${theme.colors.navTextColor}`,
                    paddingRight: "8px",
                  }}
                  aria-label={
                    locale === "he" ? "למעבר לאתר בשפה האנגלית" : "switch site to hebrew language"
                  }
                  onClick={() => mainMenuClick(locale === "en" ? "HE" : "EN", "")}
                >
                  <NewText
                    component="p"
                    desktopFontSize={16}
                    desktopLineHeight={"20px"}
                    color={theme.colors.navTextColor}
                  >
                    {locale === "en" ? "HE" : "EN"}
                  </NewText>
                </Link>
                {locale === "en" && (
                  <StyledSearchButton onClick={handleOpenModal} aria-expanded={modalOpen}>
                    <SearchIconNew
                      color={theme.colors.navTextColor}
                      width={20}
                      height={20}
                      aria-label="search icon"
                    />
                  </StyledSearchButton>
                )}
              </StyledLanguageSwitcher>
            </Desktop>
          </StyledContainer>
        </Container>
      </StyledHeader>
      <SearchModal
        open={modalOpen}
        handleClose={handleCloseModal}
        culture={culture}
        pathString={pathString}
        idNumber={idNumber}
        searchRouteString={searchRouteString}
      />

      <SideMenu menuItems={data} open={open} setOpen={setOpen} companyName={companyName} />
    </StyledRelative>
  )
}

export default Header
