"use client"
import styled from "@emotion/styled"
import NewText from "../NewText/NewText"

import { useTheme } from "@emotion/react"
import Link from "next/link"
import { buttonClick, getUmbracoPDFUrl } from "utils"
import { sharedColors } from "../theme/colors"
import FileIcon2 from "./FileIcon2"

const StyledWrapper = styled.div`
  box-shadow: 0px 4px 12px 0px #00000029;
  border-radius: 16px;
  min-height: 70px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  width: 100%;
  svg {
    width: 24px;
    height: 24px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    min-height: 160px;
    max-width: 182px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
`

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-decoration: none;
  text-align: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: 5px;
  }
`

type Props = {
  data: { url: string; target: string; type?: string; name: string }
  view: string
  mainTitle: string
}

function ReportsWrapperSmallNew({ data, mainTitle }: Props) {
  const theme = useTheme()
  return (
    <StyledWrapper>
      <FileIcon2 fill={theme.colors.propBlue} />
      <StyledLink
        href={getUmbracoPDFUrl(data.url) || ""}
        target={data.target}
        onClick={() => buttonClick(data.name, mainTitle)}
      >
        <NewText
          component={"p"}
          desktopFontSize={18}
          desktopLineHeight={"26px"}
          desktopFontWeight={400}
          mobileFontSize={16}
          mobileLineHeight={"23px"}
          color={sharedColors.black}
        >
          {mainTitle || data.name}
        </NewText>
      </StyledLink>
    </StyledWrapper>
  )
}

export default ReportsWrapperSmallNew
