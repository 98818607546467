"use client"

import styled from "@emotion/styled"
import { useLottie } from "lottie-react"
import Link from "next/link"
import { SimplyIcon } from "./icons"
import * as animationData from "./LottieAnimations/Paint-Brush.json"

export const StickyWrap = styled.div<{ z?: number }>`
  position: fixed;
  width: fit-content;
  bottom: 16px;
  z-index: 100;
  right: 16px;
  @media (min-width: 768px) {
    right: 0;
    bottom: 24px;
  }
  @media (min-width: 1040px) {
    right: calc((100% - 1040px) / 2);
    bottom: 24px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: calc((100% - 1280px) / 2);
    bottom: 24px;
  }

  z-index: ${(props) => props.z};
`
const StyledAbsolute = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const RelativeDiv = styled.div`
  position: relative;
`
const StyledButton = styled(Link)<{ locale: string }>`
  background-color: white;
  margin-bottom: 16px;
  min-height: 53px;
  min-width: 150px;
  width: fit-content;
  border: 1px solid #ff9d00;
  box-shadow: 0px 4px 8px 0px #00000029;
  border-radius: 73px;
  padding-left: ${(props) => props.locale === "en" && "24px"};
  padding-right: ${(props) => props.locale === "he" && "24px"};
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.locale === "he" ? "row-reverse" : "row")};
  ${(props) => props.theme.breakpoints.up("md")} {
  }
`

const StyledAnimationWrapper = styled.div`
  width: 52px;
  height: 52px;
`

type Props = {
  link: string
  companyName: string
  locale: string
}

function StickyButton({ link, locale }: Props) {
  const defaultOptions = {
    animationData: animationData,
    loop: true,
  }
  const { View } = useLottie(defaultOptions)
  return (
    <>
      <StickyWrap>
        <StyledAbsolute>
          <RelativeDiv>
            <StyledButton locale={locale} href={link}>
              <SimplyIcon />
              <StyledAnimationWrapper>{View}</StyledAnimationWrapper>
            </StyledButton>
          </RelativeDiv>
        </StyledAbsolute>
      </StickyWrap>
    </>
  )
}

export default StickyButton
