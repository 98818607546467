"use client"
import { useTheme } from "@emotion/react"
import { SVGProps } from "react"

interface BannerBottomIcon extends SVGProps<SVGSVGElement> {
  companyName?: string
}

const BannerBottomIcon = (props: BannerBottomIcon) => {
  const theme = useTheme()
  const hasDifferentOpacity = ["Ashbond"]
  const isSolid = ["Ashbond", "Ashkalit", "Quarries", "Itumit", "Israbeton"]
  return (
    <svg
      width="156"
      height="107"
      viewBox="0 0 156 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M153.639 53.486C156.159 54.3667 156.177 57.9238 153.666 58.83L23.4454 105.831C12.0369 109.949 5.3765e-07 101.496 1.04723e-06 89.3672L4.64275e-06 3.78788C4.72479e-06 1.83518 1.92765 0.466875 3.77102 1.11109L153.639 53.486Z"
        fill="url(#paint0_linear_18269_156318)"
        fill-opacity={
          props.companyName && hasDifferentOpacity.includes(props.companyName) ? "0.1" : "0.24"
        }
      />
      <defs>
        <linearGradient
          id="paint0_linear_18269_156318"
          x1="80.5874"
          y1="-0.206787"
          x2="80.5874"
          y2="114.293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme.colors.heroSvgIconColor} />
          <stop
            offset="1"
            stop-color={theme.colors.heroSvgIconColor}
            stop-opacity={props.companyName && isSolid.includes(props.companyName) ? "1" : "0"}
          />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default BannerBottomIcon
