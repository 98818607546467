"use client"
import styled from "@emotion/styled"
import Image from "next/image"
import "swiper/css"
import { extractImage, extractProperty, getUmbracoMediaUrl } from "utils"
import { Container, NewText, useIsDesktop } from "../"
import UseImageAccordion, { AccordionItem } from "./UseImageAccordion"

const StyledImageWrapper = styled.div`
  display: block;
  margin: 0 auto 8px;
  width: 183.33px;
  height: 88px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: 0;
  }
`

const StyledDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 32px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 40px;
  }
`
const TextBox = styled.div`
  text-align: center;
`
type Props = {
  data: any
  locale: string
  homepage?: boolean
}

export const NewActiveArms: React.FC<Props> = ({ data, homepage }) => {
  const bgLogo = extractImage(data, "bgLogo")
  const isDesktop = useIsDesktop()
  const title = extractProperty(data, "title")
  const text = extractProperty(data, "text")
  const arms = extractProperty(data, "arms")
  const armsList = arms.map((prop: any, i: number) => {
    return {
      id: i,
      logo: extractImage(prop, "logo"),
      transparentLogo: extractProperty(prop, "transparentLogo"),
      image: extractImage(prop, "image"),
      imageMobile: extractImage(prop, "imageMobile"),
      title: extractProperty(prop, "title"),
      content: extractProperty(prop, "content"),
      link: extractProperty(prop, "link")?.[0]?.url,
    }
  }) as AccordionItem[]
  return (
    <Container
      style={{
        paddingTop: isDesktop ? (homepage ? "60px" : "0") : homepage ? "40px" : "0",
        paddingBottom: isDesktop ? (homepage ? "60px" : "0") : homepage ? "40px" : "0",
      }}
    >
      {bgLogo?.url && (
        <StyledImageWrapper>
          <Image
            src={getUmbracoMediaUrl(bgLogo.url)}
            alt={bgLogo.altText}
            style={{ objectFit: "contain" }}
            height={88}
            width={183.33}
          />
        </StyledImageWrapper>
      )}
      <TextBox>
        <NewText
          desktopFontSize={40}
          desktopFontWeight="400"
          desktopLineHeight="110%"
          mobileFontSize={32}
          mobileFontWeight="400"
          mobileLineHeight={"112.5%"}
          component="h2"
          color={"black"}
        >
          {title}
        </NewText>

        <StyledDescriptionContainer>
          <NewText
            desktopFontSize={18}
            desktopFontWeight="400"
            desktopLineHeight="26px"
            mobileFontSize={16}
            mobileFontWeight="400"
            mobileLineHeight={"23px"}
            component="div"
            color={"black"}
            dangerouslySetInnerHTML={{ __html: text }}
            maxWidth={"800px"}
          />
        </StyledDescriptionContainer>
      </TextBox>
      <UseImageAccordion accordionData={armsList} height="420px" />
    </Container>
  )
}
export default NewActiveArms
