"use client"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import Typography, { TypographyProps } from "@mui/material/Typography"
import { ElementType, ReactNode } from "react"

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => !["cssString", "columnMobile"].includes(prop),
})<{
  cssString?: string
  component: any
  columnMobile?: boolean
  color?: string
  inheritColor?: boolean
}>`
  ${(p) => css(p.cssString)}
  ${(props) => props.theme.breakpoints.up("md")} {
    color: ${({ theme, color, inheritColor }) =>
      color ? color : inheritColor ? "inherit" : theme.colors.gray};
  }
  ${({ theme, columnMobile }) =>
    theme.breakpoints.down("md") &&
    columnMobile &&
    `display: flex; flex-direction: column; align-items:center; color: ${theme.colors.black}`}
`

interface NewTextProps extends TypographyProps {
  desktopFontSize: number
  mobileFontSize?: number
  desktopLineHeight?: number | string
  mobileLineHeight?: number | string
  desktopFontWeight?: number | string
  mobileFontWeight?: number | string
  color?: string
  component: ElementType<any>
  children?: ReactNode
  opacity?: number
  smallDesktopFontSize?: number
  smallDesktopLineHeight?: number | string
  cssString?: string
  inheritColor?: boolean
  columnMobile?: boolean
}

function NewText({
  desktopFontSize,
  mobileFontSize = desktopFontSize,
  desktopLineHeight = "100%",
  mobileLineHeight = desktopLineHeight,
  desktopFontWeight = "400",
  mobileFontWeight = desktopFontWeight,
  smallDesktopFontSize = desktopFontSize,
  color,
  opacity = 1,
  inheritColor,
  children,
  component,
  smallDesktopLineHeight = desktopLineHeight,
  cssString,
  columnMobile,
  ...props
}: NewTextProps) {
  return (
    <StyledTypography
      {...props}
      columnMobile={columnMobile}
      component={component}
      color={color}
      inheritColor={inheritColor}
      sx={{
        fontSize: {
          xs: mobileFontSize,
          sm: smallDesktopFontSize,
          xl: desktopFontSize,
        },
        lineHeight: {
          xs: mobileLineHeight,
          sm: smallDesktopLineHeight,
          xl: desktopLineHeight,
        },
        fontWeight: {
          xs: mobileFontWeight,
          sm: desktopFontWeight,
        },
        opacity,
        cssString: cssString,
      }}
    >
      {children}
    </StyledTypography>
  )
}

export default NewText
