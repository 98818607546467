"use client"

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material"
import Link from "next/link"
import { useState } from "react"
import { extractMenuProperties, getNonUmbracoUrl } from "utils"
import NewText from "../NewText/NewText"
import { Mobile } from "../common/bit/MediaQueries"
import { MenuArrowDown, MenuArrowUp } from "../icons"
import { sharedColors } from "../theme/colors"

const StyledMenuItem = styled.div<{ selected: boolean; color?: string }>`
  a {
    text-decoration: none;
    color: #000;
  }
  white-space: nowrap;
  p {
    color: ${(props) =>
      props.color ||
      (props.selected
        ? props.theme.colors.selectedSubNavTextColor
        : props.theme.colors.navTextColor || "inherit")};
    font-weight: ${(props) => (props.selected ? 600 : "normal")};
  }
  padding: 13px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0;

    li {
      padding: 8px 16px;
    }
  }
`

const StyledMobileButton = styled(ListItemButton)<{
  selected?: boolean
  color: string
  isLast?: boolean
}>`
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => props.color};

  text-decoration: none;
  padding: 16px;
  p {
    color: ${(props) => props.color || (props.selected ? props.theme.colors.propBlue : "inherit")};
    font-weight: ${(props) => (props.selected ? 600 : "normal")};
  }
  path {
    fill: ${(props) => props.color || (props.selected ? props.theme.colors.propBlue : "#000000")};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 0px 24px;
    border-bottom: none;
  }
`

type Props = {
  data: any
  closeMenu: (() => void) | undefined
  color?: string
  title: string
  pathname: string
}

function SubNavLinks({
  data,
  color = sharedColors.black,
  title = "test",
  closeMenu,
  pathname,
}: Props) {
  const [isOpen, setOpen] = useState(false)
  const handleMobileClick = () => {
    setOpen(!isOpen)
  }
  const activeChildItem = data?.some((el: any) => {
    return decodeURI(pathname) + "/" === getNonUmbracoUrl(extractMenuProperties(el).url)
  })

  const theme = useTheme()
  return (
    <Mobile>
      <StyledMobileButton
        color={color}
        id="basic-button"
        aria-controls={isOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleMobileClick}
        selected={activeChildItem}
      >
        <ListItemText
          primary={
            <NewText
              component="p"
              desktopFontSize={16}
              desktopLineHeight={"20px"}
              mobileFontSize={16}
              mobileLineHeight={"14px"}
              color={sharedColors.black}
            >
              {title}
            </NewText>
          }
        />
        {!isOpen ? <MenuArrowUp fill="#1C1B1F" /> : <MenuArrowDown fill="#1C1B1F" />}
      </StyledMobileButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit style={{ paddingRight: "16px" }}>
        <List
          component="div"
          disablePadding
          style={{ borderRight: `solid 1px ${theme.colors.propBlue}52` }}
        >
          {data.map((item: any, index: number) => {
            const { title, url } = item ? extractMenuProperties(item) : { title: "", url: "/" }
            const activeSubItem = decodeURI(pathname) + "/" === getNonUmbracoUrl(url)
            return (
              <>
                <StyledMenuItem key={index.toString()} selected={activeSubItem} color={color}>
                  <Link href={getNonUmbracoUrl(url)}>
                    <ListItemButton
                      onClick={() => closeMenu && closeMenu()}
                      sx={{ padding: 0, pl: 1.5 }}
                    >
                      <ListItemText
                        primary={
                          <NewText
                            component="p"
                            desktopFontSize={16}
                            desktopLineHeight={"14px"}
                            color={sharedColors.black}
                          >
                            {title}
                          </NewText>
                        }
                      />
                    </ListItemButton>
                  </Link>
                </StyledMenuItem>
              </>
            )
          })}
        </List>
      </Collapse>
    </Mobile>
  )
}

export default SubNavLinks
