"use client"
import styled from "@emotion/styled"
import { extractProperty } from "utils/extractProperty"
import Container from "./Container"
import { ReportsWrapperSmallNew } from "./icons"
import NewText from "./NewText/NewText"
import { sharedColors as colors } from "./theme/colors"

const StyledPageDiv = styled.div<{ $costumPadding?: string }>`
  padding-top: 40px;
  padding-bottom: 0px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(p) => (p.$costumPadding ? p.$costumPadding : "80px")};
    padding-bottom: ${(p) => (p.$costumPadding ? p.$costumPadding : "0px")};
  }
`
type Props = {
  data: any
  view: string
  eventName?: string
  children: React.ReactNode
  costumPadding?: string
}

const StyledTabsWrapper = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 40px;
  grid-template-columns: 1fr;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(auto-fill, 182px);
    gap: 32px;
  }
`

function ReportsTab({ data, view, costumPadding }: Props) {
  const title = extractProperty(data, "mainTitle")
  const subTitle = extractProperty(data, "subTitle")
  const filePicker = extractProperty(data, "filePiker")

  return (
    <Container>
      <StyledPageDiv $costumPadding={costumPadding}>
        <NewText
          component={"h1"}
          desktopFontSize={40}
          desktopLineHeight={"44px"}
          desktopFontWeight={400}
          mobileFontSize={32}
          mobileLineHeight={"36px"}
          color={colors.black}
        >
          {title}
        </NewText>
        <NewText
          component={"h3"}
          desktopFontSize={32}
          desktopLineHeight={"36px"}
          desktopFontWeight={400}
          mobileFontSize={24}
          mobileLineHeight={"35px"}
          color={colors.black}
          marginTop={"16px"}
        >
          {subTitle}
        </NewText>
        <StyledTabsWrapper>
          {filePicker?.map((block: any, index: number) => {
            const fileData = block.properties.find(
              (prop: any) => prop.propertyName === "reportsPicker",
            )?.propertyValue?.links?.[0]
            const fileName = fileData?.name
            const urlData = {
              url: fileData?.url,
              target: fileData?.target,
              type: fileData?.type,
              name: fileData?.name,
            }
            return (
              <ReportsWrapperSmallNew key={index} data={urlData} view={view} mainTitle={fileName} />
            )
          })}
        </StyledTabsWrapper>
      </StyledPageDiv>
    </Container>
  )
}

export default ReportsTab
