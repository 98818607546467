import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { useLocale } from "next-intl"
import Link from "next/link"
import { footerMenuClick, getNonUmbracoUrl } from "utils"
import ImageWithAlt from "../../ImageWithAlt"
import NewText from "../../NewText/NewText"
import { Desktop } from "../../common/bit/MediaQueries"
import { FooterArrowIcon } from "../../icons"

const StyledWithBorders = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  gap: 28px;
  max-width: 100%;
  align-items: center;

  a {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    gap: 28px;
    max-width: 100%;
  }
`

const StyledLine = styled.div`
  height: 20px;
  width: 1px;
  background: ${(props) => props.theme.colors.footerTextColor};
`

const StyledLogoLink = styled(Link)<{ $locale: string }>`
  height: 56px;
  width: 110px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  padding: 8px !important;
  gap: 0px !important;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.footerLogoLinkBackgroundColor};
`

type Props = {
  desktopUrls: any
  englishDesktop: any
  parentSiteLogoDesktop: any
  parentLinkDesktop: any
}

function FooterLinksDesktop({
  desktopUrls,
  englishDesktop,
  parentSiteLogoDesktop,
  parentLinkDesktop,
}: Props) {
  const locale = useLocale()
  const siteMapUrl = (url: any) => {
    if (locale === "he") {
      return getNonUmbracoUrl(url)
    } else {
      return `en/${getNonUmbracoUrl(url)}`
    }
  }

  const theme = useTheme()

  return (
    <Desktop>
      <StyledWithBorders>
        {parentSiteLogoDesktop?.url && (
          <StyledLogoLink
            href={getNonUmbracoUrl(parentLinkDesktop[0]?.url || "")}
            $locale={locale}
            onClick={() =>
              footerMenuClick(parentSiteLogoDesktop?.altText || "parent site logo", "")
            }
          >
            {locale !== "he" && <FooterArrowIcon />}
            <ImageWithAlt
              image={parentSiteLogoDesktop}
              width={74}
              height={40}
              style={{ objectFit: "contain" }}
            />
            {locale === "he" && <FooterArrowIcon fill={theme.colors.corpBlue} />}
          </StyledLogoLink>
        )}
        {desktopUrls.map((item: any, index: number) => {
          return (
            <Link
              href={
                item.name === "sitemap" ? siteMapUrl(item?.url) : getNonUmbracoUrl(item?.url) || "#"
              }
              key={index.toString()}
              onClick={() => footerMenuClick(item?.name, "")}
            >
              <NewText
                desktopFontSize={18}
                component="p"
                desktopLineHeight="26px"
                color={theme.colors.footerTextColor}
                mobileFontSize={16}
                mobileLineHeight={"23px"}
              >
                {item?.name}
              </NewText>
              <StyledLine />
            </Link>
          )
        })}
        <Link
          href={englishDesktop?.[0]?.url || "/"}
          onClick={() => footerMenuClick(englishDesktop?.[0]?.name, "")}
        >
          <NewText
            desktopFontSize={18}
            desktopLineHeight="26px"
            color={theme.colors.footerTextColor}
            component="p"
            mobileFontSize={16}
            mobileLineHeight={"23px"}
          >
            {englishDesktop?.[0]?.name}
          </NewText>
        </Link>
      </StyledWithBorders>
    </Desktop>
  )
}

export default FooterLinksDesktop
